import { Select, Switch, Button } from 'antd';
import InputFieldTitle from '../../../common/components/InputFieldTitle';
import styles from './form.module.scss';
import InputField from '../../../common/components/InputField';
import './form.global.scss';
import ItemFormInputNumberField from '../../../itemForm/components/ItemFormInputNumberField';
import ItemFormInputDateTimeField from '../../../itemForm/components/ItemFormInputDateTimeField';
import { Option } from 'antd/lib/mentions';
import { SelectData } from '../../../common/types';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { getDescriptorsList } from '../../../../api/filter';
import { useDispatch } from 'react-redux';
import { getCountries } from '../../../../api/filter';
import MultiFileUploader from '../../../common/components/MultiFileUploader';
import { createDraftItem, editDraftItem } from '../../../../api/items';
import { useActions } from '../../../../hooks/useActions';
import { convertToItemFormFieldsWithSize } from '../../../itemForm/utils/convertToItemFormFieldsWithSize';
import { Popup } from './popup';
import { DownloadOutlined } from '@ant-design/icons';
import UploadIcon from '../../../common/icons/upload.png';
import { getSizes } from '../../../../api/filter/index';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as TrashIconGrey } from '../../../common/icons/trashGrey.svg';
import { getLanguages } from '../../../../api/filter';
import { getAllergenCodes } from '../../../../api/filter';
import { getLevelOfContainmentCodes } from '../../../../api/filter';
import { getNutrientCodes } from '../../../../api/filter';
import { getMeasurementPrecisions } from '../../../../api/filter';
import { getUnitMeasurements } from '../../../../api/filter';
import { getDerivationCodes } from '../../../../api/filter';

interface FormProps {
  element: any;
  dataForm: any;
  setIsMainFieldsFull: any;
  isLastTab: any;
  isDraft: boolean;
}

interface OptionsProps {
  created_at: string;
  id: number;
  item_field_id: number;
  name: string;
  updated_at: string;
}

interface ItemProps {
  created_at: string;
  id: 6;
  is_file: boolean;
  is_size: boolean;
  is_language: boolean;
  is_multiple: boolean;
  item_tab_id: number;
  key: any;
  mandatory: string | null;
  status: boolean;
  updated_at: string;
  name: string;
  field_type_id: number;
  field_select_options: OptionsProps[];
}

export const Form = ({
  element,
  dataForm,
  setIsMainFieldsFull,
  isLastTab,
  isDraft,
}: FormProps) => {
  const dispatch = useDispatch();
  const { descriptorsList, countriesList, languagesList, allergenCodesList, levelOfContainmentList, nutrientCodes, measurementPrecisions, unitMeasurements, derivationCodes} = useAppSelector(
    (state) => state.optionLists
  );

  const { setIsOpenDraftItemsFormModal } = useActions();
  const { company_id }: any = useAppSelector((state) => state.itemForm);
  const [formData, setFormData] = useState<any>({
    id: null,
    gtin: null,
    description: null,
    trade_item_descriptor_id: null,
    brand: null,
    target_market_id: null,
  });

  const [formDataError, setFormDataError] = useState<any>({});
  const [dinamicFormData, setDinamicFormData] = useState<any>([]);
  const [dinamicFormDataError, setDinamicFormDataError] = useState<any>([]);
  const [mandatoryDinamicField, setMandatoryDinamicField] = useState({});
  const [mandatoryMetaDataFields, setMandatoryMetaDataFiealds] = useState({});
  const [chosenImage, setChosenImage] = useState(0);
  const [fileUrl, setFileUrl] = useState<any>();
  const { popup }: any = useAppSelector((state) => state.itemForm);
  const { user }: any = useAppSelector((state) => state.auth);
  const selectedTab: any = useAppSelector(
    (state) => state.modals.draftItemsFormModalActiveTab
  );
  const [gtinError, setGtinError] = useState<string>('');
  const [metaData, setMetaData] = useState<any[]>([]);
  const [metaError, setMetaError] = useState<boolean>(false);
  const [newLink, setNewLink] = useState('');
  const [newLinkError, setNewLinkError] = useState(false);

  const [multiLanguageData, setMultiLanguageData] = useState<any[]>([
    {
      key: '',
      data: {
        languageText: '',
        languageCode: ''
      }
    }
  ]);

  const [itemAllergens, setItemAllergens] = useState<any[]>([]);
  const [allergenCode, setAllergenCode] = useState('');
  const [allergenName, setAllergenName] = useState('');
  const [levelOfContainmentCode, setLevelOfContainmentCode] = useState('');
  const [levelOfContainmentName, setLevelOfContainmentName] = useState('');

  const [itemNutritionals, setItemNutritionals] = useState<any>([]);

  useEffect(() => {
    if (!metaData) return;
    if (typeof metaData[chosenImage]?.file !== 'string') {
      setFileUrl('');
    }
  }, [chosenImage]);

  useEffect(() => {
    if (!dataForm?.file_links?.length) return;
    const data: any = [];
    dataForm.file_links.forEach((elem: any) => {
      const fieldsObj: any = {};
      elem.fields.forEach((e: any) => {
        fieldsObj[e.item_field.id] = e.value;
      });
      data.push({
        file: elem.file,
        fields: fieldsObj,
      });
    });
    setMetaData(data);
  }, [dataForm]);

  useEffect(() => {
    if (!element) return;

    if (element?.name === 'digital assets') {
      const data: any = {};

      element.items.forEach((elem: any) => {
        if (elem.is_file) {
          data[elem.id] = '';
        }
      });

      const dataFormObj = { ...formData };
      const result: any = [];
      dataFormObj?.file_links?.forEach((elem: any) => {
        if (elem.fields.length) {
          const fieldsObj: any = {};
          elem.fields.forEach((e: any) => {
            if (e.item_field.id) {
              fieldsObj[e.item_field.id] = e.value;
            }
          });
          result.push({
            file: elem.file,
            fields: { ...fieldsObj },
          });
        } else {
          result.push({
            file: elem.file,
            fields: { ...data },
          });
        }
      });
      setMetaData(result);
    }
  }, [element, formData]);

  const handleClear = () => {
    const dinamicData = { ...dinamicFormData };
    element?.items.forEach((elem: any) => {
      dinamicData[elem.key] = null;
    });
    const data = { ...formData };
    if (element?.name === 'main') {
      data.gtin = null;
      data.description = null;
      data.trade_item_descriptor_id = null;
      data.brand = null;
      data.target_market_id = null;
    }
    if (element?.name === 'measurements') {
      data.quantity = null;
    }
    if (element?.name === 'digital assets') {
      data.file_links = [];
    }
    setDinamicFormData(dinamicData);
    setFormData(data);
  };

  useEffect(() => {
    if (!dataForm) {
      setIsMainFieldsFull(false);
    } else {
      setIsMainFieldsFull(true);
    }
  }, [dataForm]);

  useEffect(() => {
    if (!element) return;
    const data: any = {};
    element?.items?.forEach((elem: any) => {
      if (
        elem.mandatory === 'Yes' &&
        +elem.field_type_id !== 2 &&
        !elem.is_file
      ) {
        data[elem.key] = true;
      }
    });
    setMandatoryDinamicField(data);

    const metaData: any = {};
    element?.items?.forEach((elem: any) => {
      if (
        elem.mandatory === 'Yes' &&
        +elem.field_type_id !== 2 &&
        elem.is_file
      ) {
        metaData[elem.id] = true;
      }
    });
    setMandatoryMetaDataFiealds(metaData);
  }, [element]);

  // inserts STATIC values from an open element
  useEffect(() => {
    if (!dataForm || !descriptorsList || !countriesList) return;
    let data: any = { ...formData };
    let dataErr: any = {};
    let dinamicData: any = { ...dinamicFormData };
    data.id = dataForm.id;
    if (element?.name === 'main') {
      data.gtin = dataForm.gtin;
      data.description = dataForm.description;
      const itemDescriptor: any = descriptorsList.find(
        (elem: any) => +elem.value === +dataForm.trade_item_descriptor_id
      );
      data.trade_item_descriptor_id = itemDescriptor
        ? itemDescriptor?.value + ''
        : null;
      data.brand = dataForm.brand;
      const targetMarketId: any = countriesList.find(
        (elem: any) => +elem.value === +dataForm.target_market_id
      );
      data.target_market_id = targetMarketId
        ? targetMarketId?.value + ''
        : null;
    }
    if (element?.name === 'measurements') {
      data.quantity = dataForm.quantity;
      dataErr.quantity = false;
    }
    if (element?.name === 'digital assets') {
      data.file_links = dataForm?.file_links;
    }
    dataForm?.item_forms?.forEach((elem: any) => {
      if(elem.item_field !== undefined && elem.item_field !== null) {
        dinamicData[elem.item_field.key] = elem.value;  
      }
      
    });

    if(dataForm !== undefined && dataForm !== null) {
      if(dataForm?.item_allergens !== undefined && (allergenCode === '')) {
        setItemAllergens(dataForm.item_allergens)
      }
    }
 if(dataForm !== undefined && dataForm !== null) {
      if(dataForm?.item_nutrients !== undefined) {
        if(dataForm?.item_nutrients.length > 0) {
          const nutrients: any = [];
          
          dataForm.item_nutrients.map((value: any) => {
            nutrients[value.nutrientCode] = {
              nutrientName: value.nutrientName, 
              nutrientCode: value.nutrientCode, 
              qtyMeasurementPrecisionCode: value.qtyMeasurementPrecisionCode,
              qtyMeasurementPrecisionValue: value.qtyMeasurementPrecisionValue, 
              unitMeasure: value.unitMeasure, 
              percentMeasurementPrecisionCode: value.percentMeasurementPrecisionCode, 
              percentMeasurementPrecisionValue: value.percentMeasurementPrecisionValue, 
              derivationCode: value.derivationCode
            }
          });
          setItemNutritionals(nutrients);
        } else {
          const nutrientCodesReceived: any = [];
          nutrientCodes.map((value: any) => {
            nutrientCodesReceived[value.value] = {nutrientName: value.name, nutrientCode: value.value, qtyMeasurementPrecisionCode: '', qtyMeasurementPrecisionValue: '', unitMeasure: '', percentMeasurementPrecisionCode: '', percentMeasurementPrecisionValue: '', derivationCode: ''}
          });

          setItemNutritionals(nutrientCodesReceived);
        }
        
      } 

      
    }
    
    setFormDataError(dataErr);
    setDinamicFormData(dinamicData);
    setFormData(data);
  }, [dataForm, element, countriesList, descriptorsList]);

  useEffect(() => {
    dispatch(getDescriptorsList());
    dispatch(getCountries());
    dispatch(getLanguages());
    dispatch(getSizes());
    dispatch(getAllergenCodes());
    dispatch(getLevelOfContainmentCodes());
    dispatch(getNutrientCodes());
    dispatch(getMeasurementPrecisions());
    dispatch(getUnitMeasurements());
    dispatch(getDerivationCodes());
  }, []);

  // inserts DINAMIC values from an open element
  useEffect(() => {
    if (!element || !dataForm) return;
    const data: any = { ...dinamicFormData };
    var isLanguageKeys: Array<string> = [];
    element?.items?.forEach((elem: ItemProps) => {
      const item = dataForm?.item_forms?.find((elemDraft: any) => {
        if(elemDraft.item_field !== undefined && elemDraft.item_field !== null) {
          return elemDraft.item_field.key === elem.key  
        }
        
      });
      data[elem.key] = item ? item.value : null;
      //Keep language field keys
      if(item) {
        if(item.item_field.is_language) {
          isLanguageKeys.push(elem.key);  
        }
        
      }
    });

    data['isLanguageKeys'] = isLanguageKeys;
    setDinamicFormData(data);
  }, [element, dataForm]);

  const handleChange = (name: string, value: any) => {
    let data = { ...dinamicFormData };
    data[name] = value;
    setDinamicFormData(data);
  };

  // check if a field is filled at fill time
  useEffect(() => {
    const errors: any = { ...formDataError };
    const errorDinamic = { ...dinamicFormDataError };
    for (let prop in formData) {
      if (formData[prop]) {
        errors[prop] = false;
      }
    }
    for (let prop in mandatoryDinamicField) {
      if (dinamicFormData[prop]) {
        errorDinamic[prop] = false;
      }
    }
    setDinamicFormDataError(errorDinamic);
    setFormDataError(errors);
    getLanguageTextField();
  }, [formData, dinamicFormData]);

  const convertFilesForRequest = (files: any) => {
    if (!files) {
      return [];
    } else {
      return files.map((item: any) => {
        if (item.file.originFileObj) {
          const data: any = [];
          for (let prop in item.fields) {
            data.push({
              field_id: +prop,
              value: item.fields[prop],
            });
          }
          return {
            fields: data,
            file: item.file.originFileObj,
          };
        }
        if (typeof item.file === 'string') {
          const data: any = [];
          for (let prop in item.fields) {
            data.push({
              field_id: +prop,
              value: item.fields[prop],
            });
          }
          return {
            fields: data,
            file: item.file,
          };
        } else {
          const data: any = [];
          for (let prop in item.fields) {
            data.push({
              field_id: +prop,
              value: item.fields[prop],
            });
          }
          return {
            file: item.file.name,
            fields: data,
          };
        }
      });
    }
  };

  useEffect(() => {
    if (!element || !metaData) return;
    if (element.name === 'digital assets') {
      const errorMetaData: any = { ...mandatoryMetaDataFields };
      const data = JSON.parse(JSON.stringify(metaData));
      let isValid = true;
      data.forEach((elem: any) => {
        for (let prop in elem.fields) {
          if (typeof elem.file === 'string') {
            for (let p in errorMetaData) {
              if (prop === p) {
                if (!elem.fields[prop]) {
                  isValid = false;
                }
              }
            }
          }
        }
      });
      if (isValid) {
        setMetaError(false);
      }
    }
  }, [element, metaData]);

  const handleSubmit = () => {
    let isValid = true;
    const errors = { ...formDataError };

    if (element?.name === 'main') {
      if (!/^[0-9]+$/.test(formData.gtin)) {
        isValid = false;
        errors.gtin = true;
      }
      if (!formData.gtin) {
        isValid = false;
        errors.gtin = true;
      }
      if (!formData.description) {
        isValid = false;
        errors.description = true;
      }
      if (!formData.trade_item_descriptor_id) {
        isValid = false;
        errors.trade_item_descriptor_id = true;
      }
      if (!formData.brand) {
        isValid = false;
        errors.brand = true;
      }
      if (!formData.target_market_id) {
        isValid = false;
        errors.target_market_id = true;
      }
    }
    if (element?.name === 'measurements') {
      if (!formData.quantity) {
        isValid = false;
        errors.quantity = true;
      }
    }
    if (element?.name === 'digital assets') {
      if (newLinkError) {
        isValid = false;
      }
      const errorMetaData: any = { ...mandatoryMetaDataFields };
      const data = JSON.parse(JSON.stringify(metaData));
      data.forEach((elem: any) => {
        for (let prop in elem.fields) {
          if (typeof elem.file === 'string') {
            for (let p in errorMetaData) {
              if (prop === p) {
                if (!elem.fields[prop]) {
                  isValid = false;
                  setMetaError(true);
                }
              }
            }
          }
        }
      });
    }
    setFormDataError(errors);

    const errorDinamicData: any = { ...mandatoryDinamicField };
    const errorData = { ...dinamicFormDataError };
    for (let prop in errorDinamicData) {
      if (!dinamicFormData[prop]) {
        isValid = false;
        errorData[prop] = true;
      }
    }
    setDinamicFormDataError(errorData);

    if (isValid) {
      const converFormData: any = { ...formData };
      delete converFormData.file_links;
      //let copyOfDinamicFormData = { ...dinamicFormData }
      delete dinamicFormData.isLanguageKeys
      //setDinamicFormData(copyOfDinamicFormData)
      const requestData = {
        ...converFormData,
        files: convertFilesForRequest(metaData),
        item_forms: JSON.stringify({ ...dinamicFormData }),
        item_allergens: JSON.stringify({ ...itemAllergens }),
        item_nutrients: JSON.stringify({ ...itemNutritionals }),
      };
      if (!dataForm) {
        if (element?.name === 'main') {
          dispatch(createDraftItem(company_id, requestData, selectedTab));
        } else {
          dispatch(
            editDraftItem(company_id, requestData, selectedTab, isLastTab)
          );
        }
      } else {
        dispatch(
          editDraftItem(company_id, requestData, selectedTab, isLastTab)
        );
      }
    }
    // }
  };

  useEffect(() => {
    if (!formData.gtin) return;
    if (!/^[0-9]+$/.test(formData.gtin)) {
      setGtinError('GTIN is not valid');
    } else {
      setGtinError('');
    }
  }, [formData.gtin]);

  const setMetaDatavalues = (id: number, value: any) => {
    if (!fileUrl) return;
    const data = [...metaData];
    const elem = data.find((e: any) => {
      return e.file === fileUrl;
    });

    elem.fields[id] = value;

    setMetaData(data);
  };

  const getMetaValue = (id: number) => {
    if (!fileUrl) {
      return '';
    } else {
      const data = [...metaData];
      const elem = data.find((e: any) => {
        return e.file === fileUrl;
      });
      return elem?.fields?.[id];
    }
  };

  const setNewLinkFunc = (e: any) => {
    setNewLink(e.target.value);
    let data = JSON.parse(JSON.stringify(metaData));
    data.push({
      file: e.target.value,
      fields: {},
    });

    setMetaData(data);
  };

  useEffect(() => {
    if (newLink.length > 255) {
      setNewLinkError(true);
    } else {
      setNewLinkError(false);
    }
  }, [newLink]);
  
  const setLanguageTextCodeMultiple = (name: any, index: any, fieldKey: string, value: any) => {
    let copyOfMultiLanguageData = {...multiLanguageData}

    if(fieldKey === 'text') {
      if(copyOfMultiLanguageData[name] !== undefined) {
        copyOfMultiLanguageData[name][index]['languageText'] =  value 
      } else {
        copyOfMultiLanguageData[name] = [{'languageText': value, 'languageCode': ''}]
      }
    } else if(fieldKey === 'code') {
      if(copyOfMultiLanguageData[name] !== undefined) {
        copyOfMultiLanguageData[name][index]['languageCode'] =  value
      } else {
        copyOfMultiLanguageData[name] = [{'languageText': '', 'languageCode': value}]
      }
    }
    setMultiLanguageData(copyOfMultiLanguageData);
  }

  const getLanguageTextField = () => {
    //To initialize languages fields
    if(multiLanguageData.length > 0) {
      if(multiLanguageData[0].key === '') {
        let copyOfMultiLanguageData = {...multiLanguageData}

        if(dinamicFormData.isLanguageKeys !== undefined && dinamicFormData.isLanguageKeys.length > 0) {
          dinamicFormData.isLanguageKeys.map((name: any) => {
            if(dinamicFormData[name] !== undefined && typeof dinamicFormData[name] === 'string') {
              let currentData = JSON.parse(dinamicFormData[name]);
              
              if(currentData.length > 0) {
                copyOfMultiLanguageData[name] = currentData;
              }
            } else if(dinamicFormData[name] === null) {
              copyOfMultiLanguageData[name] = [{'languageText': '', 'languageCode': '' }];
            }
          });
          setMultiLanguageData(copyOfMultiLanguageData);
        }      
      }
    }    
  };

  const addNewLanguageLine = (name: any) => {
    let copyOfMultiLanguageData = {...multiLanguageData}
    copyOfMultiLanguageData[name][multiLanguageData[name].length] = {'languageText': '', 'languageCode': ''};
    setMultiLanguageData(copyOfMultiLanguageData)
  }

  const removeLanguageLine = (name: any, index: any) => {
    let copyOfMultiLanguageData = { ...multiLanguageData }

    if(copyOfMultiLanguageData[name] !== undefined) {
      if(copyOfMultiLanguageData[name][index] !== undefined) {
        copyOfMultiLanguageData[name].splice(index, 1)
        setMultiLanguageData(copyOfMultiLanguageData)
      }
    }
  }

  useEffect(() => {
    //Detects when multiLanguageData changes and update dinamicFormData
    let data = { ...dinamicFormData };

    Object.keys(multiLanguageData).map((itemLanguage: any) => {
      if(data[itemLanguage] !== undefined) {
        data[itemLanguage] = multiLanguageData[itemLanguage];
        setDinamicFormData(data);
      }
    });
  }, [multiLanguageData]);

  const addAllergenLine = () => {
    setItemAllergens( // Replace the state
      [ // with a new array
        ...itemAllergens, // that contains all the old items
        {allergenCode, allergenName, levelOfContainmentCode, levelOfContainmentName} // and one new item at the end
      ]
    );
  };

  const removeAllergenLine = (allergen: any) => {
    setItemAllergens(
      itemAllergens.filter(a => a.allergenCode !== allergen.allergenCode)
    );
  }

  const setNutrientsLine = (nutrientCode: any, fieldLabel: any, fieldValue: any) => {
    let copyOfItemNutritionals = {...itemNutritionals}
    copyOfItemNutritionals[nutrientCode][fieldLabel] = fieldValue
    setItemNutritionals(copyOfItemNutritionals)
  }

  useEffect(() => {
    
  }, [itemNutritionals])

  return (
    <>
      <form className="itemFormFieldDinamicForm">
        <Popup
          status="success"
          className={popup === 'success' ? styles.active : ''}
        />
        <Popup
          status="error"
          className={popup === 'error' ? styles.active : ''}
        />
        <Popup
          status="gtinError"
          className={popup === 'gtinError' ? styles.active : ''}
        />
        {element?.name === 'main' && (
          <>
            <div className={styles.fieldWrapper}>
              <div className={styles.fieldName}>GTIN</div>
              <div className={styles.errorWrap}>
                <InputField
                  maxLength={14}
                  value={formData.gtin}
                  className={styles.field}
                  disabled={user.is_admin || !isDraft ? true : false}
                  placeholder="GTIN"
                  required={false}
                  errorMessage={
                    gtinError
                      ? gtinError
                      : formDataError.gtin
                      ? 'This field is required'
                      : ''
                  }
                  onChange={(e: any) => {
                    const data = { ...formData };
                    data.gtin = e.target.value;
                    setFormData(data);
                  }}
                />
              </div>
            </div>
            <div className={styles.fieldWrapper}>
              <div className={styles.fieldName}>Description</div>
              <InputField
                value={formData.description}
                className={styles.field}
                errorMessage={
                  formDataError.description ? 'This field is required' : ''
                }
                disabled={user.is_admin || !isDraft ? true : false}
                placeholder="Description"
                required={false}
                // onBlur={() => setFieldTouched('description')}
                onChange={(e: any) => {
                  const data = { ...formData };
                  data.description = e.target.value;
                  setFormData(data);
                }}
              />
            </div>
            <div className={styles.fieldWrapper}>
              <div className={styles.fieldName}>Level</div>

              <InputFieldTitle
                title=""
                required={false}
                errorMessage={
                  formDataError.trade_item_descriptor_id
                    ? 'This field is required'
                    : ''
                }
              >
                <Select
                  size="middle"
                  className={styles.selectField}
                  disabled={user.is_admin || !isDraft ? true : false}
                  value={formData.trade_item_descriptor_id}
                  placeholder="Level"
                  // onBlur={() =>
                  //     setFieldTouched('trade_item_descriptor_id')
                  // }
                  onChange={(value: any) => {
                    const data = { ...formData };
                    data.trade_item_descriptor_id = value;
                    setFormData(data);
                  }}
                >
                  {descriptorsList.map((sortOption: SelectData) => (
                    <Option key={sortOption.name} value={`${sortOption.value}`}>
                      {sortOption.name}
                    </Option>
                  ))}
                </Select>
              </InputFieldTitle>
            </div>
            <div className={styles.fieldWrapper}>
              <div className={styles.fieldName}>Brand</div>
              <InputField
                value={formData.brand}
                className={styles.field}
                disabled={user.is_admin || !isDraft ? true : false}
                placeholder="Brand"
                required={false}
                errorMessage={
                  formDataError.brand ? 'This field is required' : ''
                }
                // onBlur={() => setFieldTouched('brand')}
                onChange={(e: any) => {
                  const data = { ...formData };
                  data.brand = e.target.value;
                  setFormData(data);
                }}
              />
            </div>
            <div className={styles.fieldWrapper}>
              <div className={styles.fieldName}>Target Market</div>
              <InputFieldTitle
                title=""
                required={false}
                errorMessage={
                  formDataError.target_market_id ? 'This field is required' : ''
                }
              >
                <Select
                  size="middle"
                  className={styles.selectField}
                  disabled={user.is_admin || !isDraft ? true : false}
                  placeholder="Target Market"
                  value={formData.target_market_id}
                  // onBlur={() =>
                  //     setFieldTouched('target_market_id')
                  // }
                  onChange={(value: any) => {
                    const data = { ...formData };
                    data.target_market_id = value;
                    setFormData(data);
                  }}
                >
                  {countriesList.map((sortOption: SelectData) => (
                    <Option key={sortOption.name} value={`${sortOption.value}`}>
                      {sortOption.name}
                    </Option>
                  ))}
                </Select>
              </InputFieldTitle>
            </div>
          </>
        )}
        {element?.name === 'measurements' && (
          <>
            <div className={styles.fieldWrapper}>
              <div className={styles.fieldName}>Quantity</div>
              <div className={styles.inputQuantity}>
                <ItemFormInputNumberField
                  errorMessage={
                    formDataError.quantity ? 'This field is required' : ''
                  }
                  key={'quantity'}
                  fieldValue={formData.quantity}
                  fieldName={''}
                  isFieldDisabled={user.is_admin || !isDraft ? true : false}
                  setFieldValue={(value: any) => {
                    const data = { ...formData };
                    data.quantity = value;
                    setFormData(data);
                  }}
                />
              </div>
            </div>
          </>
        )}
        {element?.name === 'digital assets' && (
          <>
            <div className={styles.imagesWrapper}>
              <MultiFileUploader
                isHideUploadButton={user.is_admin || !isDraft ? true : false}
                fieldsForImage={formData?.file_links}
                imagesData={
                  formData?.file_links?.length &&
                  formData.file_links[0]?.file !== null
                    ? formData?.file_links?.map((file: any, index: any) => {
                        if (typeof file.file === 'string') {
                          return {
                            name: file.file,
                            url: file.file,
                            uid: `${file.file}/${index}`,
                          };
                        } else {
                          return file.file;
                        }
                      })
                    : []
                }
                setImages={(filesData) => {
                  // delete one last file
                  if (filesData.length === 0) {
                    setChosenImage(-1);
                    const data = { ...formData };
                    data.file_links = [];
                    return setFormData(data);
                  }

                  // delete files
                  if (filesData !== undefined && formData.file_links !== undefined && filesData.length < formData.file_links.length) {
                    const index = formData.file_links.findIndex(
                      (value: any) => {
                        let res = true;
                        filesData.forEach((item) => {
                          if (
                            item.file.name === value.file.name ||
                            item.file.name === value.file ||
                            item.file === value.file
                          )
                            res = false;
                        });
                        return res;
                      }
                    );
                    const result = [...formData.file_links];
                    result.splice(index, 1);
                    setChosenImage(result.length - 1);
                    const data = { ...formData };
                    data.file_links = result;
                    return setFormData(data);
                  }

                  // add files
                  const arr = filesData;
                  if(formData.file_links !== undefined) { arr.splice(0, formData.file_links.length) };
                  const result = [...formData.file_links, ...arr];
                  setChosenImage(result.length - 1);
                  const data = { ...formData };
                  data.file_links = result;
                  setFileUrl('');
                  setFormData(data);
                }}
                setFileUrl={(fileUrl) => {
                  setFileUrl(fileUrl);
                }}
                setChosenImage={setChosenImage}
              />
            </div>
            {metaError && (
              <div className={styles.errorMetaData}>
                All fields with * must be filled in. Please check the data
                again.
              </div>
            )}
            <div className={styles.fieldWrapper}>
              <div className={styles.fieldName}>FILE LINK</div>
              <div className={styles.inputUrlWrapper}>
                {user.is_admin ||
                  (!isDraft ? (
                    ''
                  ) : (
                    <div className={styles.urlButton}>
                      <DownloadOutlined
                        style={{ cursor: 'pointer' }}
                        className={styles.downloadIcon}
                      />
                    </div>
                  ))}
                <InputField
                  value={newLink}
                  disabled={user.is_admin || !isDraft ? true : false}
                  className={styles.field}
                  placeholder="URL"
                  required={false}
                  onChange={(e) => setNewLinkFunc(e)}
                  errorMessage={
                    newLinkError &&
                    'The number of characters must not exceed 255'
                  }
                />
              </div>
            </div>
            <div className={styles.fieldWrapper}>
              <div className={styles.fieldName}>Product Image URL</div>
              <div className={styles.inputUrlWrapper}>
                {user.is_admin ||
                  (!isDraft ? (
                    ''
                  ) : (
                    <a
                      className={styles.urlButton}
                      href={fileUrl}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={UploadIcon} className={styles.downloadIcon} />
                    </a>
                  ))}
                <InputField
                  value={fileUrl}
                  disabled={user.is_admin || !isDraft ? true : false}
                  className={styles.field}
                  placeholder="URL"
                  required={false}
                />
              </div>
            </div>
          </>
        )}
        {element?.items?.map((elem: ItemProps) => {
          if (elem.field_type_id === 1 && elem.name !== 'size') {
            if (element?.name === 'digital assets' && elem.is_file) {
              return (
                <div className={styles.fieldWrapper}>
                  <div className={styles.fieldName}>
                    {elem.name} {elem.mandatory === 'Yes' && '*'}
                  </div>
                  <InputFieldTitle title="" required={false}>
                    <Select
                      size="middle"
                      className={`${styles.selectField} ${styles.itemFormFieldForm}`}
                      disabled={
                        user.is_admin || !isDraft || !fileUrl ? true : false
                      }
                      value={getMetaValue(elem.id)}
                      placeholder={elem.name}
                      onChange={(value) => setMetaDatavalues(elem.id, value)}
                    >
                      {elem?.field_select_options?.map(
                        (sortOption: OptionsProps) => (
                          <Option
                            key={sortOption.name}
                            value={`${sortOption.name}`}
                          >
                            {sortOption.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </InputFieldTitle>
                </div>
              );
            }

            if (element?.name !== 'digital assets') {
              return (
                <div className={styles.fieldWrapper}>
                  <div className={styles.fieldName}>{elem.name}</div>
                  <InputFieldTitle
                    title=""
                    required={false}
                    errorMessage={
                      dinamicFormDataError[elem.key]
                        ? 'This field is required'
                        : ''
                    }
                  >
                    <Select
                      size="middle"
                      className={`${styles.selectField} ${styles.itemFormFieldForm}`}
                      disabled={user.is_admin || !isDraft ? true : false}
                      value={dinamicFormData[elem.key]}
                      placeholder={elem.name}
                      onChange={(value) => handleChange(elem.key, value)}
                    >
                      {elem?.field_select_options?.map(
                        (sortOption: OptionsProps) => (
                          <Option
                            key={sortOption.name}
                            value={`${sortOption.name}`}
                          >
                            {sortOption.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </InputFieldTitle>
                </div>
              );
            }
          }
        })}

        {element?.items?.map((elem: ItemProps) => {
          if (elem.field_type_id === 3) {
            if (element?.name === 'digital assets' && elem?.is_file) {
              return (
                <div className={styles.fieldWrapper}>
                  <div className={styles.fieldName}>
                    {elem.name} {elem.mandatory === 'Yes' && '*'}
                  </div>
                  <InputField
                    value={getMetaValue(elem.id)}
                    className={styles.field}
                    disabled={
                      user.is_admin || !isDraft || !fileUrl ? true : false
                    }
                    placeholder={elem.name}
                    required={false}
                    onChange={(e) => setMetaDatavalues(elem.id, e.target.value)}
                  />
                </div>
              );
            }
            if (element?.name !== 'digital assets') {
              //For any tab different to digital assets and is_language and/or is_multiple is true
              if(elem?.is_language) {              
                return (
                  <div>
                    <div className={styles.fieldWrapper}>
                      <div className={styles.fieldName} style={{width: '30%'}}>{elem.name}</div>
                      <InputField
                        style={{width: '305px'}}
                        value={multiLanguageData[elem.key] !== undefined?multiLanguageData[elem.key][0].languageText: ''}
                        className={styles.field}
                        disabled={user.is_admin || !isDraft ? true : false}
                        placeholder={elem.name}
                        required={false}
                        errorMessage={
                          dinamicFormDataError[elem.key]
                            ? 'This field is required'
                            : ''
                        }
                        onChange={(e) => {
                          setLanguageTextCodeMultiple(elem.key, '0', 'text', e.target.value);
                        }}
                      />
                      <InputFieldTitle
                          title=""
                          required={false}
                          style={{width: elem.is_multiple?'270px':'305px'}}
                        >
                          <Select
                            value={multiLanguageData[elem.key] !== undefined?multiLanguageData[elem.key][0].languageCode: ''}
                            size="middle"
                            className={styles.selectField}
                            style={{width: elem.is_multiple?'270px':'305px'}}
                            disabled={user.is_admin || !isDraft ? true : false}
                            placeholder="Language"
                            onChange={(e) => {
                              setLanguageTextCodeMultiple(elem.key, '0', 'code', e);
                            }}
                          >
                            {languagesList.map((sortOption: SelectData) => (
                              <Option key={sortOption.name} value={`${sortOption.value}`}>
                                {sortOption.name}
                              </Option>
                            ))}
                          </Select>
                      </InputFieldTitle>
                      <div style={{width: elem.is_multiple?'30px':'auto'}}>
                        {elem.is_multiple?
                            <Button
                                disabled={user.is_admin || !isDraft ? true : false}
                                style={{ borderRadius: '8px', padding: '0 8px'}}
                                onClick={() => addNewLanguageLine(elem.key)}
                            >
                                <PlusOutlined />
                            </Button>
                        :''}
                      </div>
                      
                    </div>
                  {elem.is_multiple?
                    <div>
                      <div>
                        {multiLanguageData[elem.key] !== undefined ?
                          Object.entries(multiLanguageData[elem.key]).map((value: any, index: any) => {
                            if(multiLanguageData[elem.key].length > 1 && index > 0) {
                              return ( 
                                <div className={styles.fieldWrapper}>
                                  <div className={styles.fieldName} style={{width: '30%'}}>{elem.name}</div>
                                   <InputField
                                    style={{width: '305px'}}
                                    value={value[1].languageText !== undefined?value[1].languageText: ''}
                                    className={styles.field}
                                    disabled={user.is_admin || !isDraft ? true : false}
                                    placeholder={elem.name}
                                    required={false}
                                    errorMessage={
                                      dinamicFormDataError[elem.key]
                                        ? 'This field is required'
                                        : ''
                                    }
                                    onChange={(e) => {
                                      setLanguageTextCodeMultiple(elem.key, index, 'text', e.target.value);
                                    }}
                                  />
                                  <InputFieldTitle
                                    title=""
                                    required={false}
                                    style={{width: '270px'}}
                                  >
                                    <Select
                                      value={value[1].languageCode !== undefined?value[1].languageCode: ''}
                                      size="middle"
                                      className={styles.selectField}
                                      disabled={user.is_admin || !isDraft ? true : false}
                                      placeholder="Language"
                                      onChange={(e) => {
                                        setLanguageTextCodeMultiple(elem.key, index, 'code', e);
                                      }}
                                    >
                                      {languagesList.map((sortOption: SelectData) => (
                                        <Option key={sortOption.name} value={`${sortOption.value}`}>
                                          {sortOption.name}
                                        </Option>
                                      ))}
                                    </Select>
                                </InputFieldTitle>
                                <div style={{width: '30px'}}>
                                <Button
                                    disabled={user.is_admin || !isDraft ? true : false}
                                    style={{ padding: '0 5px', border: 'none'}}
                                    onClick={() => removeLanguageLine(elem.key, index)}
                                >
                                    <TrashIconGrey />
                                </Button>
                                </div>
                                </div>
                              )  
                            }
                            
                          })
                        :''}
                      </div>
                    </div>
                  :''}
                  </div>
                );
                
              } else {
                return (
                  <div className={styles.fieldWrapper}>
                    <div className={styles.fieldName}>{elem.name}</div>
                    <InputField
                      value={dinamicFormData[elem.key]}
                      className={styles.field}
                      disabled={user.is_admin || !isDraft ? true : false}
                      placeholder={elem.name}
                      required={false}
                      errorMessage={
                        dinamicFormDataError[elem.key]
                          ? 'This field is required'
                          : ''
                      }
                      onChange={(e) => handleChange(elem.key, e.target.value)}
                    />
                  </div>
                );
              }
            }
          }
        })}

        {element?.items?.map((elem: ItemProps) => {
          if (elem.field_type_id === 4 || elem.field_type_id === 1) {
            if (elem.field_type_id === 4 && elem.is_size) {
              if (element?.name !== 'digital assets') {
                const el = element?.items?.find((e: any) => {
                  return e.key === `${elem.key}Size`;
                });

                return (
                  <div className={styles.fieldWrapper}>
                    <div className={styles.fieldName}>{elem.name}</div>
                    <div style={{ display: 'flex' }}>
                      <ItemFormInputNumberField
                        errorMessage={
                          dinamicFormDataError[elem.key]
                            ? 'This field is required'
                            : ''
                        }
                        key={elem.key}
                        fieldValue={dinamicFormData[elem.key]}
                        fieldName={''}
                        isFieldDisabled={
                          user.is_admin || !isDraft ? true : false
                        }
                        setFieldValue={(value: any) => {
                          const data = {
                            ...dinamicFormData,
                          };
                          data[elem.key] = value;
                          setDinamicFormData(data);
                        }}
                      />
                      <InputFieldTitle
                        title=""
                        required={false}
                        errorMessage={
                          dinamicFormDataError[el.key]
                            ? 'This field is required'
                            : ''
                        }
                      >
                        <Select
                          style={{ width: '150px' }}
                          size="middle"
                          className={`${styles.selectField} ${styles.itemFormFieldForm}`}
                          disabled={user.is_admin || !isDraft ? true : false}
                          value={dinamicFormData[el.key]}
                          placeholder={el.name}
                          onChange={(value) => handleChange(el.key, value)}
                        >
                          {el?.field_select_options?.map(
                            (sortOption: OptionsProps) => (
                              <Option
                                key={sortOption.name}
                                value={`${sortOption.name}`}
                              >
                                {sortOption.name}
                              </Option>
                            )
                          )}
                        </Select>
                      </InputFieldTitle>
                    </div>
                  </div>
                );
              }
            }

            if (elem.field_type_id === 4 && !elem.is_size) {
              if (element?.name === 'digital assets' && elem?.is_file) {
                return (
                  <div className={styles.fieldWrapper}>
                    <div className={styles.fieldName}>
                      {elem.name} {elem.mandatory === 'Yes' && '*'}
                    </div>
                    <ItemFormInputNumberField
                      key={elem.key}
                      fieldValue={getMetaValue(elem.id)}
                      fieldName={''}
                      isFieldDisabled={
                        user.is_admin || !isDraft || !fileUrl ? true : false
                      }
                      setFieldValue={(value: any) => {
                        setMetaDatavalues(elem.id, value);
                      }}
                    />
                  </div>
                );
              }
              if (element?.name !== 'digital assets') {
                return (
                  <div className={styles.fieldWrapper}>
                    <div className={styles.fieldName}>{elem.name}</div>
                    <div className={styles.inputQuantity}>
                      <ItemFormInputNumberField
                        errorMessage={
                          dinamicFormDataError[elem.key]
                            ? 'This field is required'
                            : ''
                        }
                        key={elem.key}
                        fieldValue={dinamicFormData[elem.key]}
                        fieldName={''}
                        isFieldDisabled={
                          user.is_admin || !isDraft ? true : false
                        }
                        setFieldValue={(value: any) => {
                          const data = {
                            ...dinamicFormData,
                          };
                          data[elem.key] = value;
                          setDinamicFormData(data);
                        }}
                      />
                    </div>
                  </div>
                );
              }
            }
          }
        })}

        {element?.items?.map((elem: ItemProps) => {
          if (elem.field_type_id === 5) {
            if (element?.name === 'digital assets' && elem?.is_file) {
              return (
                <div className={styles.fieldWrapper}>
                  <ItemFormInputDateTimeField
                    key={elem.name}
                    fieldName={`${elem.name}  ${
                      elem.mandatory === 'Yes' && '*'
                    }`}
                    fieldValue={getMetaValue(elem.id)}
                    isFieldDisabled={
                      user.is_admin || !isDraft || !fileUrl ? true : false
                    }
                    setFieldValue={(value) => {
                      setMetaDatavalues(elem.id, value);
                    }}
                  />
                </div>
              );
            }

            if (element?.name !== 'digital assets') {
              return (
                <>
                  <div className={styles.fieldWrapper}>
                    <ItemFormInputDateTimeField
                      errorMessage={
                        dinamicFormDataError[elem.key]
                          ? 'This field is required'
                          : ''
                      }
                      key={elem.name}
                      fieldName={elem.name}
                      fieldValue={dinamicFormData[elem.key]}
                      isFieldDisabled={user.is_admin || !isDraft ? true : false}
                      setFieldValue={(value) => {
                        const data = { ...dinamicFormData };
                        data[elem.key] = value;
                        setDinamicFormData(data);
                      }}
                    />
                  </div>
                </>
              );
            }
          }
        })}
        {element?.items?.map((elem: ItemProps) => {
          if (elem.field_type_id === 2) {
            if (element?.name === 'digital assets' && elem?.is_file) {
              return (
                <div className={styles.fieldWrapper}>
                  <div className={styles.fieldName}>{elem.name} </div>
                  <Switch
                    disabled={
                      user.is_admin || !isDraft || !fileUrl ? true : false
                    }
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={
                      getMetaValue(elem.id) == '0' ||
                      getMetaValue(elem.id) == '' ||
                      getMetaValue(elem.id) == null
                        ? false
                        : true
                    }
                    onChange={(value) => {
                      setMetaDatavalues(elem.id, value);
                    }}
                  />
                </div>
              );
            }
            if (element?.name !== 'digital assets') {
              return (
                <div className={styles.fieldWrapper}>
                  <div className={styles.fieldName}>{elem.name}</div>
                  <Switch
                    disabled={user.is_admin || !isDraft ? true : false}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={
                      dinamicFormData[elem.key] == '0' ||
                      dinamicFormData[elem.key] === null
                        ? false
                        : true
                    }
                    onChange={(value) => {
                      handleChange(elem.key, value);
                    }}
                  />
                </div>
              );
            }
          }
        })}

        {/* digital assets NO is_file */}
        {element?.items?.map((elem: ItemProps) => {
          if (elem.field_type_id === 1 && elem.name !== 'size') {
            if (element?.name === 'digital assets' && !elem.is_file) {
              return (
                <div className={styles.fieldWrapper}>
                  <div className={styles.fieldName}>{elem.name}</div>
                  <InputFieldTitle
                    title=""
                    required={false}
                    errorMessage={
                      dinamicFormDataError[elem.key]
                        ? 'This field is required'
                        : ''
                    }
                  >
                    <Select
                      size="middle"
                      className={`${styles.selectField} ${styles.itemFormFieldForm}`}
                      disabled={user.is_admin || !isDraft ? true : false}
                      value={dinamicFormData[elem.key]}
                      placeholder={elem.name}
                      onChange={(value) => handleChange(elem.key, value)}
                    >
                      {elem?.field_select_options?.map(
                        (sortOption: OptionsProps) => (
                          <Option
                            key={sortOption.name}
                            value={`${sortOption.name}`}
                          >
                            {sortOption.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </InputFieldTitle>
                </div>
              );
            }
          }
        })}

        {element?.items?.map((elem: ItemProps) => {
          if (elem.field_type_id === 3) {
            if (element?.name === 'digital assets' && !elem?.is_file) {
              return (
                <div className={styles.fieldWrapper}>
                  <div className={styles.fieldName}>{elem.name}</div>
                  <InputField
                    value={dinamicFormData[elem.key]}
                    className={styles.field}
                    disabled={user.is_admin || !isDraft ? true : false}
                    placeholder={elem.name}
                    required={false}
                    errorMessage={
                      dinamicFormDataError[elem.key]
                        ? 'This field is required'
                        : ''
                    }
                    onChange={(e) => handleChange(elem.key, e.target.value)}
                  />
                </div>
              );
            }
          }
        })}

        {element?.items?.map((elem: ItemProps) => {
          if (elem.field_type_id === 4 || elem.field_type_id === 1) {
            if (elem.field_type_id === 4 && !elem.is_size) {
              if (element?.name === 'digital assets' && !elem?.is_file) {
                return (
                  <div className={styles.fieldWrapper}>
                    <div className={styles.fieldName}>{elem.name}</div>
                    <ItemFormInputNumberField
                      errorMessage={
                        dinamicFormDataError[elem.key]
                          ? 'This field is required'
                          : ''
                      }
                      key={elem.key}
                      fieldValue={dinamicFormData[elem.key]}
                      fieldName={''}
                      isFieldDisabled={user.is_admin || !isDraft ? true : false}
                      setFieldValue={(value: any) => {
                        const data = {
                          ...dinamicFormData,
                        };
                        data[elem.key] = value;
                        setDinamicFormData(data);
                      }}
                    />
                  </div>
                );
              }
            }
          }
        })}

        {element?.items?.map((elem: ItemProps) => {
          if (elem.field_type_id === 5) {
            if (element?.name === 'digital assets' && !elem?.is_file) {
              return (
                <div className={styles.fieldWrapper}>
                  <ItemFormInputDateTimeField
                    errorMessage={
                      dinamicFormDataError[elem.key]
                        ? 'This field is required'
                        : ''
                    }
                    key={elem.name}
                    fieldName={elem.name}
                    fieldValue={dinamicFormData[elem.key]}
                    isFieldDisabled={user.is_admin || !isDraft ? true : false}
                    setFieldValue={(value) => {
                      const data = { ...dinamicFormData };
                      data[elem.key] = value;
                      setDinamicFormData(data);
                    }}
                  />
                </div>
              );
            }
          }
        })}
        {element?.items?.map((elem: ItemProps) => {
          if (elem.field_type_id === 2) {
            if (element?.name === 'digital assets' && !elem?.is_file) {
              return (
                <div className={styles.fieldWrapper}>
                  <div className={styles.fieldName}>{elem.name}</div>
                  <Switch
                    disabled={user.is_admin || !isDraft ? true : false}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={
                      dinamicFormData[elem.key] == '0' ||
                      dinamicFormData[elem.key] === null
                        ? false
                        : true
                    }
                    onChange={(value) => {
                      handleChange(elem.key, value);
                    }}
                  />
                </div>
              );
            }
          }
        })}
        {element?.name === 'Allergens' && (
          <>
            <div className={styles.fieldWrapper}>
              <div className={styles.fieldName}>Allergen Code</div>
              <InputFieldTitle
                title=""
                required={false}
              >
                <Select
                  size="middle"
                  className={styles.selectField}
                  disabled={user.is_admin || !isDraft ? true : false}
                  placeholder="Allergen Type Code"
                  onChange={(value, data: any) => {
                    setAllergenCode(value)
                    setAllergenName(data.key)
                  }}
                >
                  {allergenCodesList.map((sortOption: SelectData) => (
                    <Option key={sortOption.name} value={`${sortOption.value}`}>
                      {sortOption.name}
                    </Option>
                  ))}
                </Select>
              </InputFieldTitle>
              <div className={styles.fieldName}>Level Of Containment</div>
              <InputFieldTitle
                title=""
                required={false}
              >
                <Select
                  size="middle"
                  className={styles.selectField}
                  disabled={user.is_admin || !isDraft ? true : false}
                  placeholder="Level Of Containment List"
                  onChange={(value, data: any) => {
                    setLevelOfContainmentCode(value);
                    setLevelOfContainmentName(data.key)
                  }}
                >
                  {levelOfContainmentList.map((sortOption: SelectData) => (
                    <Option key={sortOption.name} value={`${sortOption.value}`}>
                      {sortOption.name}
                    </Option>
                  ))}
                </Select>
              </InputFieldTitle>
              <div>
                <Button
                    disabled={user.is_admin || !isDraft ? true : false}
                    style={{ borderRadius: '8px', padding: '0 8px'}}
                    onClick={addAllergenLine}
                >
                    <PlusOutlined />
                </Button>
              </div>
            </div>
            {itemAllergens.map((allergen: any, index) => {
              return  <div className={styles.fieldWrapper}>
                        <div className={styles.fieldName} style={{width: '40%'}}>{allergen.allergenName}</div>
                        <div className={styles.fieldName} style={{width: '40%'}}>{allergen.levelOfContainmentName}</div>
                        <Button
                            disabled={user.is_admin || !isDraft ? true : false}
                            style={{ padding: '0 5px', border: 'none'}}
                            onClick={() => removeAllergenLine(allergen)}
                        >
                            <TrashIconGrey />
                        </Button>
                      </div>
            })}
          </>
        )}
        {element?.name === 'Nutritional' && (
          <>
          <div className={styles.fieldWrapper} style={{height: '40px'}}>
            <div style={{width: '100px'}}></div>
            <div style={{width: '155px'}}>Measurement Precision</div>
            <div style={{width: '40px'}}>Value</div>
            <div style={{width: '112px'}}>Unit of Measure</div>
            <div style={{width: '155px'}}>Measurement Precision</div>
            <div style={{width: '75'}}>Percentage</div>
            <div style={{width: '100px'}}>Derivation Code</div>
          </div>
          {nutrientCodes.map((nutrient: any, index: any) => {
            return <div>
              <div className={styles.fieldWrapper} style={{marginBottom: '12px'}}>
                <div className={styles.fieldName} style={{width: '100px'}}>{nutrient.name}</div>
                <InputFieldTitle
                  title=""
                  required={false}
                >
                  <Select
                    size="middle"
                    className={styles.selectField}
                    style={{width: '160px'}}
                    disabled={user.is_admin || !isDraft ? true : false}
                    //placeholder="Measurement Precision"
                    value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['qtyMeasurementPrecisionCode']:''}
                    onChange={(value, data: any) => {
                      setNutrientsLine(nutrient.value, 'qtyMeasurementPrecisionCode', value)
                    }}
                  >
                    {measurementPrecisions.map((sortOption: SelectData) => (
                      <Option key={sortOption.name} value={`${sortOption.value}`}>
                        {sortOption.name}
                      </Option>
                    ))}
                  </Select>
                </InputFieldTitle>
                <InputFieldTitle
                  title=""
                  required={false}
                >
                <InputField
                  className={styles.field}
                  style={{width: '70px'}}
                  disabled={user.is_admin || !isDraft ? true : false}
                  value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['qtyMeasurementPrecisionValue']:''}
                  required={false}
                  onChange={(e) => setNutrientsLine(nutrient.value, 'qtyMeasurementPrecisionValue', e.target.value)}
                />
                </InputFieldTitle>
                <InputFieldTitle
                  title=""
                  required={false}
                >
                  <Select
                    size="middle"
                    className={styles.selectField}
                    style={{width: '90px'}}
                    disabled={user.is_admin || !isDraft ? true : false}
                    value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['unitMeasure']:''}
                    onChange={(value, data: any) => {
                      setNutrientsLine(nutrient.value, 'unitMeasure', value);
                    }}
                  >
                    {unitMeasurements.map((sortOption: SelectData) => (
                      <Option key={sortOption.name} value={`${sortOption.value}`}>
                        {sortOption.name}
                      </Option>
                    ))}
                  </Select>
                </InputFieldTitle>
             
                  <InputFieldTitle
                    title=""
                    required={false}
                  >
                    <Select
                      size="middle"
                      className={styles.selectField}
                      style={{width: '160px'}}
                      disabled={user.is_admin || !isDraft ? true : false}
                      value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['percentMeasurementPrecisionCode']:''}
                      onChange={(value, data: any) => {
                        setNutrientsLine(nutrient.value, 'percentMeasurementPrecisionCode', value);
                        //setLevelOfContainmentName(data.key)
                      }}
                    >
                      {measurementPrecisions.map((sortOption: SelectData) => (
                        <Option key={sortOption.name} value={`${sortOption.value}`}>
                          {sortOption.name}
                        </Option>
                      ))}
                    </Select>
                  </InputFieldTitle>
                  <InputFieldTitle
                    title=""
                    required={false}
                  >
                  <InputField
                    value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['percentMeasurementPrecisionValue']:''}
                    className={styles.field}
                    style={{width: '70px'}}
                    disabled={user.is_admin || !isDraft ? true : false}
                    
                    required={false}
                    onChange={(e) => setNutrientsLine(nutrient.value, 'percentMeasurementPrecisionValue', e.target.value)}
                  /></InputFieldTitle>
                  <InputFieldTitle
                    title=""
                    required={false}
                  >
                    <Select
                      size="middle"
                      className={styles.selectField}
                      style={{width: '100px'}}
                      disabled={user.is_admin || !isDraft ? true : false}
                      value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['derivationCode']:''}
                      onChange={(value, data: any) => {
                        setNutrientsLine(nutrient.value, 'derivationCode', value);
                        //setLevelOfContainmentName(data.key)
                      }}
                    >
                      {derivationCodes.map((sortOption: SelectData) => (
                        <Option key={sortOption.name} value={`${sortOption.value}`}>
                          {sortOption.name}
                        </Option>
                      ))}
                    </Select>
                  </InputFieldTitle>
              </div>
            </div>
          })}
          </>
        )}
      </form>
      {user.is_admin || !isDraft ? (
        <div className={styles.wrapButton}></div>
      ) : (
        <div className={styles.wrapButton}>
          <div className={styles.clearButton} onClick={handleClear}>
            Clear
          </div>
          <div className={styles.saveButton} onClick={handleSubmit}>
            {element?.name === 'main' ? 'Next' : isLastTab ? 'Confirm' : 'Save'}
          </div>
        </div>
      )}
    </>
  );
};
