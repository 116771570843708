import { FC, useEffect } from "react";
import AppLayout from "../../common/components/AppLayout";
import styles from "./productsPage.module.scss";
import DraftProductsList from "../components/DraftProductsList";
import DraftProductsModalsGroup from "../components/DraftProductsModalsGroup";
import ActiveProductsList from "../components/ActiveProductsList";
import ActiveProductsModalsGroup from "../components/ActiveProductsModalsGroup";
import { useActions } from "../../../hooks/useActions";
import { GoldOutlined } from '@ant-design/icons';

const ProductsPage: FC = (): JSX.Element => {
  const {
    cleareActiveProductsStore,
    cleareDraftProductsStore,
    clearFilter,
    clearFilterControl,
    clearAdditionalFilter,
    clearAdditionalFilterControl,
  } = useActions();

  useEffect(() => {
    clearFilter();
    clearFilterControl();
    clearAdditionalFilter();
    clearAdditionalFilterControl();

    return () => {
      cleareActiveProductsStore();
      cleareDraftProductsStore();
    };
  }, [
    clearAdditionalFilter,
    clearAdditionalFilterControl,
    clearFilter,
    clearFilterControl,
    cleareActiveProductsStore,
    cleareDraftProductsStore,
  ]);

  return (
    <AppLayout
      title="Products"
      headerLogo={<GoldOutlined />}
    >
      <DraftProductsList showCreate={true} showImages={true} size={300}/>
      <DraftProductsModalsGroup />
      { /*
      <ActiveProductsList size={719}/>
      <ActiveProductsModalsGroup /> */
      }
    </AppLayout>
  );
};

export default ProductsPage;
