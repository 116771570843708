import { Row } from 'antd';
import { InputProps } from 'antd/lib/input';
import ProductHierarchyElement from './ProductHierarchyElement';
import separator from './separator.png';

type ProductHierarchyInputProps = {
    items: any[],
    big?: boolean,
    selectedGtin?: string,
} & InputProps;

const ProductHierarchy = ({
    items,
    big,
    selectedGtin,
    ...props
}: ProductHierarchyInputProps) => {
    
    let c = items.length

    return (<div style={{minWidth: '180px', display: 'inline-block'}}>
     <>
        {items.map((item: any, index: any) => {
            return <>
                <ProductHierarchyElement descriptor={item.descriptor} big={!!big} gtin={item.gtin} click={props.onClick} highlight={selectedGtin && selectedGtin=== item.gtin}/>
                {index !== c - 1 ? <img src={separator} style={{verticalAlign: 'middle', margin: '0px 5px'}}/> : ''}
            </>
        })}
      </>
    </div>)
}

export default ProductHierarchy;