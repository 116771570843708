import { Checkbox, Pagination, Table } from "antd";
import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { ValidationStatus } from "../../../common/constants/status";
import styles from "./draftProductsList.module.scss";
import { useActions } from "../../../../hooks/useActions";
import DraftProductsFilter from "../DraftProductsFilter/indexRetailer";
import {
  DeletionActivateDeactiveItemProps,
  DeletionActivateDeactiveProductProps,
} from "../../types";
import {
  getAllDraftProducts,
  // getDraftProductById,
  getAllDraftProductsForView,
  getDraftProductById,
  validateDraftProduct,
} from "../../../../api/products";
import {
    getRetailerItemByGtin
} from '../../../../api/items';
import { ReactComponent as CheckMarkIcon } from "../../../common/icons/checkMark.svg";
import { ReactComponent as CrossIcon } from "../../../common/icons/cross.svg";
import { ReactComponent as DraftProductIcon } from "../../../common/icons/draftProduct.svg";
import { checkValidateFields } from "../../utils/checkValidateFields";
import ProductItemsTable from "../ProductItemsTable";
import ProductHierarchy from "../../../common/components/ProductHierarchy";
import { useNavigate } from "react-router-dom";
import noImage from "../../../common/icons/noImage.png"

interface DrafProductListProps {
    title?: string;
    showImages?: boolean;
    showCheckbox?: boolean;
    showFilters?: boolean;
    showPagination?: boolean;
    pageSize?: number;
    size?: number;
    clickNavigates?: boolean;
    showCreate?: boolean;
}

const DraftProductsList: FC<DrafProductListProps> = ({
    title,
    showImages,
    showCheckbox,
    showFilters,
    showPagination,
    pageSize,
    size,
    clickNavigates,
    showCreate
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    setFilter,
    setIsOpenDraftProductsFormModal,
    setDraftProductsForActivationDeletion,
    cleareDraftProductStore,
    setIsOpenProductFormForView,
  } = useActions();

  const {
    draftProducts,
    draftProduct,
    totalProducts,
    isLoading,
    draftProductsForActivationDeletion,
  } = useAppSelector((state) => state.draftProducts);

  const {
    isChangeProductsDeletionActivationActions,
    draftProductSuccessfulStatus,
  } = useAppSelector((state) => state.modals);

  const { userCompanyId } = useAppSelector((state) => state.companies);
  const { user } = useAppSelector((state) => state.auth);
  const filter = useAppSelector((state) => state.filter);
  const navigate = useNavigate();
  title = title ?? 'Drafts';
  size = size ?? 500;
  const [perPage, setPerPage] = useState(pageSize || 15);

  const getScroll = () => {
    let s = size !== undefined && size !== null ?
        `calc(100vh - ${size}px)` :
        `300px`;
    return s;
  }

  useEffect(() => {
    if (!user?.is_admin) {
      dispatch(getAllDraftProducts(userCompanyId as number, filter));
    } else {
      dispatch(getAllDraftProductsForView(filter));
    }

    if (
      draftProduct &&
      draftProduct.validated === ValidationStatus.INCOMPLETE 
      //&& checkValidateFields(draftProduct)
    ) {
      dispatch(
        validateDraftProduct(userCompanyId as number, {
          ...draftProduct,
          items: draftProduct.items,
        })
      );
    }
  }, [
    dispatch,
    filter,
    draftProduct,
    draftProductSuccessfulStatus,
    isChangeProductsDeletionActivationActions,
    user?.is_admin,
    userCompanyId,
  ]);

  const getColumns = () => {
    let columns = [];
    if(showImages) {
        columns.push({
        title: 'PHOTO',
        dataIndex: "photo",
        key: "photo",
    })};

    if(showCheckbox) {
        columns.push({
        title: !user?.is_admin && (
          <Checkbox
            className={styles.mainCheckbox}
            checked={draftProductsForActivationDeletion.length !== 0}
            onChange={(e) => {
              if (e.target.checked) {
                const checkAllDraftProducts = draftProducts?.map(
                  (draftProduct) => {
                    return { isChecked: true, id: draftProduct.id };
                  }
                );
                setDraftProductsForActivationDeletion(
                  checkAllDraftProducts as DeletionActivateDeactiveItemProps[]
                );
              } else {
                setDraftProductsForActivationDeletion([]);
              }
            }}
          />
        ),
        dataIndex: "check",
        align: "center" as "center",
        key: "check",
    },)};

    
    columns = columns.concat([
    {
      title: "PRODUCT ID",
      dataIndex: "gtin",
      key: "gtin",
    },
    {
      title: "PRODUCT DESCRIPTION",
      dataIndex: "description",
      key: "description",
    },
    {
        title: "BRAND",
        dataIndex: "brand",
        key: "brand",
    },
    {
        title: "COMPANY",
        dataIndex: "company",
        key: "company",
    },
    {
        title: "Category Name",
        dataIndex: "gpc",
        key: "gpc",
    },
    {
        title: "SKU",
        dataIndex: "skuid",
        key: "skuid",
    },
    {
        title: "HIERARCHY",
        dataIndex: "hierarchy",
        key: "hierarchy",
    },
    {
        title: "",
        dataIndex: "empty",
        key: "empty",
    },
    Table.EXPAND_COLUMN,
    ]);

    

    return columns;
  };

  const getDataSource = () => {
    return draftProducts?.map(
        ({ id, description, date, items, company_name, gtin, photo, brand, gpc, skuid, validated }) => {
        return {
          key: id,
          check: !user?.is_admin && showCheckbox && (
            <Checkbox
              checked={
                draftProductsForActivationDeletion.find(
                  (value: DeletionActivateDeactiveProductProps) =>
                    value.id === id
                )?.isChecked
              }
              className={styles.checkbox}
              onChange={(e) => {
                if (e.target.checked) {
                  const data = { isChecked: e.target.checked, id };
                  setDraftProductsForActivationDeletion([
                    ...draftProductsForActivationDeletion,
                    data as DeletionActivateDeactiveProductProps,
                  ]);
                } else {
                  setDraftProductsForActivationDeletion(
                    draftProductsForActivationDeletion.filter(
                      (product) => product.id !== id
                    )
                  );
                }
              }}
            />
          ),
          photo: (
            showImages && photo ?
            <div style={{borderRadius: '8px', border: '1px solid #BFBFBF', textAlign: 'center', padding: '0', overflow: 'hidden', width: '40px'}}>
                {
                    photo.file.startsWith('https://media.trustedsource.co.za') ? 
                    <img src={photo?.file} style={{width: '100%', verticalAlign: 'middle'}}/>
                    :
                    <img src={'https://media.trustedsource.co.za/medium?url=' + photo?.file} style={{width: '100%', verticalAlign: 'middle'}}/>
                }
            </div> :
            <div style={{textAlign: 'center', padding: '0', overflow: 'hidden', width: '40px'}}>
                {
                    <img src={noImage} style={{width: '100%', verticalAlign: 'middle'}}/>
                }
            </div>
          ),
          gtin: (
            <div
              className={styles.gtin}
              onClick={() => {
                // dispatch(getRetailerItemByGtin(gtin));
                // navigate(`/items/${gtin}/details`);
                if(clickNavigates) { 
                    navigate(`/products`) 
                }

                if (user?.is_admin) {
                    setIsOpenProductFormForView(true);
                }

                console.log(id)
                cleareDraftProductStore();
                dispatch(getDraftProductById(id as number));
                setIsOpenDraftProductsFormModal(true);
              }}
            >
              {gtin}
            </div>
          ),
          description: (
            <span
              className={
                user?.is_admin ? styles.descriptionAdmin : styles.description
              }
            >
              {description}
            </span>
          ),
        //   supplier: (
        //     <span
        //       className={
        //         user?.is_admin ? styles.descriptionAdmin : styles.description
        //       }
        //     >
        //       {items[0].informationProviderName}
        //     </span>
        //   ),
          hierarchy: (
            <ProductHierarchy 
                items={items} 
                style={{minWidth: '150px'}}
                />
          ),
          date: <div className={styles.date}>{date}</div>,
        //   validated:
        //     validated === ValidationStatus.INCOMPLETE ? (
        //       <div className={styles.buttonStatusInvalidate}>
        //         <CrossIcon className={styles.buttonIcon} />
        //         {ValidationStatus.INCOMPLETE}
        //         <div />
        //       </div>
        //     ) : (
        //       <div className={styles.buttonStatusValidate}>
        //         <CheckMarkIcon className={styles.buttonIcon} />
        //         {ValidationStatus.COMPLETE}
        //         <div />
        //       </div>
        //     ),
          company: (
            <div className={styles.companyName}>{company_name}</div>
          ),
          brand: (
            <div className={styles.companyName}>{brand}</div>
          ),
          gpc: (
            <div className={styles.companyName}>{gpc}</div>
          ),
          skuid: (
            <div className={styles.companyName}>{skuid}</div>
          ),
          items,
        };
      }
    );
  };

  return (
    <>
      <DraftProductsFilter
        setOpenModal={setIsOpenDraftProductsFormModal}
        totalDraftProductsCount={totalProducts}
        countCheckedDraftProducts={draftProductsForActivationDeletion.length}
        title={title}
        showCreate={showCreate}
      />
      <div className={styles.tableWrapper}>
        <Table
          className={styles.table}
          locale={{
            emptyText: (
              <div>
                <DraftProductIcon className={styles.emptyIcon} />
                <div>No drafts yet</div>
              </div>
            ),
          }}
          dataSource={getDataSource()}
          expandable={{
            expandedRowRender: (product) => (
              <ProductItemsTable productItems={product.items} />
            ),
          }}
          columns={getColumns()}
          pagination={false}
          scroll={{ y: getScroll() }}
          loading={isLoading}
        />
      </div>
      <div className={styles.footerWrapper}>
      {showPagination === undefined || showPagination === true ?
        <Pagination
          disabled={draftProducts?.length === 0}
          pageSize={filter.paginate}
          className={styles.pagination}
          current={filter.page}
          onChange={(page, perPage) => {
            if (perPage) {
              dispatch(setFilter({ page, paginate: perPage }));
              setDraftProductsForActivationDeletion([]);
            } else {
              dispatch(setFilter({ page }));
              setDraftProductsForActivationDeletion([]);
            }
            setPerPage(perPage)
          }}
          total={totalProducts || 15}
          showSizeChanger={true}
          pageSizeOptions={["15", "25", "50", "100"]}
        /> : '' }
      </div>
    </>
  );
};

export default DraftProductsList;
